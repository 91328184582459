@keyframes logo-animation {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.loading {
  animation: 1s infinite;
  animation-name: logo-animation;
  width: 48px;
  height: 48px;
  background-image: url('../../img/loading.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}

.loading img {
  width: 100%;
  height: 100%;
}

#global-loading {
  opacity: 0;
  pointer-events: none;
}

#global-loading.active {
  opacity: 1;
}

#global-loading.hide {
  display: none;
}
