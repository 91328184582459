@keyframes skeleton {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 800px 0;
  }
}

.skeleton {
  background: linear-gradient(to right, #f5f7f9 8%, #eef1f3 18%, #f5f7f9 33%);
  background-size: 800px 104px;
  border-radius: 6px;
  height: 168px;
  max-width: 800px;
  overflow: hidden;
  position: relative;
  animation-name: skeleton;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-timing-function: linear;
}

.skeleton img {
  max-width: none;
  position: absolute;
  display: block;
}
